$(function(){

	$('body').attr('id',$('.page-contents').data('bodyid'));
	Fixify.matchPadding('#site-footer .row > div');
	var product_limit = 16;

    $('#more-products').click(function(){
        $('.hidden:lt(16)').removeClass('hidden');
    });

    $('.icon-back').click(function(){
        parent.history.back();
        return false;
    });

    $('.thumbnails .zoom').click(function(e){
        e.preventDefault();

        var photo_fullsize =  $(this).find('img').attr('src');

        $('.woocommerce-main-image img').attr('src', photo_fullsize);

    });

    $('#product-image .row .col-xs-3:first-child .zoom img').addClass('active');
    $('#product-image .row .zoom').on('click', function () {
        $('#product-image .zoom img').removeClass('active');
        $(this).find('img').addClass('active');
    });

    $('#homeCarousel .carousel-indicators li:first').attr('data-slide-to', '0');

    $('.pop').on('click', function() {
        $('.imagepreview').attr('src', $(this).find('img').attr('src'));
        $('#imagemodal').modal('show');
    });

    $('#more-products').trigger('click');

    //$('#product-image-zoom').elevateZoom({ zoomType: "inner", cursor: "crosshair" });

});